import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import MatchListRow from "@/game-destiny2/components/MatchListRow.jsx";
import Guardians from "@/game-destiny2/components/Profile/Guardians.jsx";
import Maps from "@/game-destiny2/components/Profile/Maps.jsx";
import MatchHistoryHeader from "@/game-destiny2/components/Profile/MatchHistoryHeader.jsx";
import { ProfileFilters } from "@/game-destiny2/components/Profile/ProfileFilters";
import Sidebar from "@/game-destiny2/components/Profile/Sidebar";
import Weapons from "@/game-destiny2/components/Profile/Weapons.jsx";
import useCurrentUserProfile from "@/game-destiny2/utils/use-current-user-profile.mjs";
import PageHeader from "@/shared/PageHeader";
import SharedProfile, { MatchList, MatchTile } from "@/shared/Profile.jsx";
import { ProfileColumn, ProfileMatchlist } from "@/shared/Profile.style.jsx";
import { filterErrState, useEvalState } from "@/util/eval-state.mjs";
import { useQuery, useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const ProfileContainer = styled("div")`
  container-type: inline-size;
  container-name: profile_container;
  width: 100%;
  max-width: calc(var(--sp-container) + var(--sp-48));
  display: flex;
  flex-direction: column;
  padding: var(--sp-4);

  .d2p-header,
  .d2p-header > div {
    max-width: calc(var(--sp-container) + var(--sp-48));
  }

  .row {
    display: flex;
    gap: var(--sp-4);
  }

  .column {
    display: flex;
    gap: var(--sp-4);
    flex-direction: column;

    &.sidebar {
      flex: 1;
      max-width: 320px;
      display: none;

      @container profile_container (min-width: 900px) {
        & {
          display: flex;
        }
      }
    }
    &.main {
      flex: 2;
    }
  }

  .stats {
    display: flex;
    gap: var(--sp-8);
    padding: var(--sp-4) var(--sp-6);
    background-color: var(--shade8);
    border: 1px solid var(--shade6);
    border-radius: var(--br-lg);
  }

  .matches {
    display: flex;
    flex-direction: column;
    gap: var(--sp-3);
  }
`;

const strings: { links: Record<string, Translation> } = {
  links: {
    overview: ["common:navigation.overview", "Overview"],
    guardians: ["destiny2:navigation.guardians", "Guardians"],
    weapons: ["common:navigation.weapons", "Weapons"],
    maps: ["common:maps", "Maps"],
  },
};

export const D2ProfileContainer: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { t } = useTranslation();
  const {
    parameters: [bungieId],
  } = useRoute();
  const { profile } = useCurrentUserProfile(bungieId);
  return (
    <ProfileContainer>
      <PageHeader
        className="d2p-header"
        imageRound
        title={profile?.bungieName}
        image={`${appURLs.BUNGIE}${profile?.iconPath}`}
        links={[
          {
            text: t(...strings.links.overview),
            url: `/destiny2/profile/${bungieId}`,
          },
          {
            text: t(...strings.links.guardians),
            url: `/destiny2/profile/${bungieId}/guardians`,
          },
          {
            text: t(...strings.links.weapons),
            url: `/destiny2/profile/${bungieId}/weapons`,
          },
          {
            text: t(...strings.links.maps),
            url: `/destiny2/profile/${bungieId}/maps`,
          },
        ]}
      />
      {children}
    </ProfileContainer>
  );
};

export const Profile = () => {
  const {
    parameters: [bungieId, tab],
  } = useRoute();
  const [seasonId] = useQuery("season");
  const [guardian] = useQuery("guardian");
  const {
    destiny2: { matchList, match: matches, profiles },
  } = useSnapshot(readState);
  const $profile = profiles[bungieId];
  const profile = useEvalState($profile);
  const guardians = profile?.memberships.flatMap?.((i) => i.guardians);
  const $matchlist = matchList[bungieId];
  const matchlist = useEvalState($matchlist);
  const matchlistFiltered = useMemo<Array<{ gameId: string }>>(() => {
    const result = [];
    if (!Array.isArray(matchlist)) return result;
    for (const gameId of matchlist) {
      const $match = matches[gameId];
      if (
        (filterErrState($match) &&
          ((seasonId && $match.seasonId !== seasonId) ||
            (
              guardian &&
              guardians &&
              $match.playerMatches.find((i) =>
                guardians.find((j) => j.characterId === i.guardian.characterId),
              )
            )?.guardian.class !== guardian)) ||
        // Check for the possibility of a player being completely inactive, disconnected or useless
        ((): boolean => {
          const player = $match?.playerMatches.find?.((i) =>
            guardians.find((j) => j.characterId === i.guardian.characterId),
          );
          if (!player) return false;
          return [
            player.kills,
            player.assists,
            player.deaths,
            player.score,
          ].every((i) => !i);
        })()
      )
        continue;
      result.push({ gameId });
    }
    return result;
  }, [matchlist, matches, seasonId, guardian, guardians]);

  return (
    <D2ProfileContainer>
      {tab !== "guardians" && <ProfileFilters />}
      {tab === "guardians" ? (
        <Guardians />
      ) : tab === "weapons" ? (
        <Weapons />
      ) : tab === "maps" ? (
        <Maps />
      ) : (
        <SharedProfile>
          <ProfileColumn className="sidebar">
            <Sidebar bungieId={bungieId} />
          </ProfileColumn>
          <ProfileColumn className="main">
            {Boolean(matchlistFiltered.length) && (
              <MatchHistoryHeader
                matchlist={matchlistFiltered}
                bungieId={bungieId}
              />
            )}
            <ProfileMatchlist>
              <MatchList
                matchList={
                  $matchlist instanceof Error ? $matchlist : matchlistFiltered
                }
              >
                {matchlistFiltered.map(({ gameId }) => {
                  const $match = matches[gameId];
                  const match = filterErrState($match);
                  return (
                    <MatchTile
                      key={gameId}
                      id={gameId}
                      match={$match}
                      height={126}
                    >
                      {!!match && (
                        <MatchListRow bungieId={bungieId} match={match} />
                      )}
                    </MatchTile>
                  );
                })}
              </MatchList>
            </ProfileMatchlist>
          </ProfileColumn>
        </SharedProfile>
      )}
    </D2ProfileContainer>
  );
};

export default Profile;

export function meta() {
  return {
    title: [`destiny2:meta.profile.title`, `Destiny Performance Overview`],
    description: [
      `destiny2:meta.profile.description`,
      `View your Destiny 2 profile and see how you perform.`,
    ],
  };
}
