import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import type { ElementOf } from "ts-essentials";

import { readState } from "@/__main__/app-state.mjs";
import type { MapStats } from "@/game-destiny2/components/Profile/types.js";
import type { Guardian } from "@/game-destiny2/models/destiny2-guardian.mjs";
import type { GuardianMapStats } from "@/game-destiny2/models/destiny2-player-map-stats.mjs";
import { bungieAsset } from "@/game-destiny2/utils/api.mjs";
import { getGuardianClassAggregates } from "@/game-destiny2/utils/get-map-aggregates.mjs";
import useProfileWeaponStats from "@/game-destiny2/utils/use-profile-weapon-stats.mjs";
import { SquareAvatar } from "@/shared/Avatar.jsx";
import DataTable from "@/shared/DataTable";
import HeadShotStats from "@/shared-fps/HeadShotStats.jsx";
import clone from "@/util/clone.mjs";
import { filterErrState, useEvalState } from "@/util/eval-state.mjs";
import { displayRate, sanitizeNumber } from "@/util/helpers.mjs";
import isEmpty from "@/util/is-empty.mjs";
import { useQuery } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const ProfileCard = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--sp-2);
  background: var(--shade7);
  border: 1px solid var(--shade6);
  border-radius: var(--br-lg);

  .card-title {
    font-size: var(--sp-4);
    font-weight: 600;
    padding: var(--sp-2);
  }

  .prow {
    display: flex;
    align-items: center;
    gap: var(--sp-4);
    padding: var(--sp-2);
    font-size: var(--sp-3_5);
    font-weight: 600;

    .avatar-label {
      display: flex;
      flex-direction: column;
      span:last-child {
        font-size: var(--sp-3);
        color: var(--shade4);
      }
    }
  }

  .card-footer {
    text-align: center;
    margin: 0 auto;
    font-size: var(--sp-3_5);
    color: var(--shade4);
    font-weight: 600;
    padding: var(--sp-3);

    .text {
      color: var(--purple);
    }
  }

  .accent {
    color: var(--turq);
    font-weight: 600;
    font-size: var(--sp-4);
  }
`;

const Headshots = ({ bungieId }: { bungieId?: string }) => {
  const {
    destiny2: { match: matches, matchList, profiles },
  } = useSnapshot(readState);
  const profile = useEvalState(profiles[bungieId]);
  const matchlist = useEvalState(matchList[bungieId]);
  const guardians = profile?.memberships.flatMap?.((i) => i.guardians);
  const playerMatches = useMemo(
    () =>
      ([matchlist, guardians].every(Array.isArray) ? matchlist : [])
        .map((id) =>
          matches[id]?.playerMatches?.find((player) =>
            guardians?.find(
              (j) => j.characterId === player.guardian.characterId,
            ),
          ),
        )
        .filter(Boolean)
        .sort((a, b) => a.startedAt.getTime() - b.startedAt.getTime())
        .slice(0, 10),
    [guardians, matches, matchlist],
  );
  const { lastNAvgHS, kills, precisionKills } = useMemo(() => {
    const aggregates = playerMatches.reduce(
      (acc, cur) => {
        acc.kills += cur.kills;
        acc.precisionKills += cur.precisionKills;
        return acc;
      },
      { kills: 0, precisionKills: 0, lastNAvgHS: 0 },
    );
    const totalKills = aggregates.kills;
    aggregates.lastNAvgHS = sanitizeNumber(
      (aggregates.precisionKills / totalKills) * 100,
    );
    return aggregates;
  }, [playerMatches]);
  return (
    <ProfileCard>
      <HeadShotStats
        hiddenLegshots
        hideHits
        lastNStats={{
          headshots: precisionKills,
          bodyshots: kills,
          legshots: 0,
          chestshots: 0,
          stomachshots: 0,
        }}
        matches={playerMatches.map((i, index) => {
          return {
            index,
            hsPercent: sanitizeNumber((i.precisionKills / i.kills) * 100),
            lastNAvgHS,
          };
        })}
      />
    </ProfileCard>
  );
};

const Guardians = ({ bungieId }: { bungieId: string }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    destiny2: { profiles, characters: $characters, guardians: $guardians },
  } = useSnapshot(readState);
  const profile = useEvalState(profiles[bungieId]);
  const characters = $characters[bungieId];
  const allGuardians = useMemo(
    () => profile?.memberships.flatMap?.((i) => i.guardians) ?? [],
    [profile?.memberships],
  );
  const guardians = allGuardians
    .map((i) => $guardians[bungieId]?.[i.characterId])
    .filter(filterErrState);
  return (
    <ProfileCard>
      <p className="card-title">
        {t("destiny2:navigation.guardians", "Guardians")}
      </p>
      <DataTable
        noResultsTitle={["destiny2:suggestions.noGuardians", "No Guardians"]}
        cols={[
          { display: t("lol:role", "Role"), primary: true, align: "left" },
          { display: t("common:kills", "Kills"), isStat: true, align: "right" },
          {
            display: t("common:stats.hsPercent", "HS%"),
            isStat: true,
            align: "right",
          },
        ]}
        rows={guardians
          .map((g) => {
            const all = g.seasonAggregates.reduce((acc, cur) => {
              for (const k in cur) {
                const value = cur[k];
                if (typeof value !== "number") return acc;
                if (typeof acc[k] !== "undefined") {
                  acc[k] = acc[k] + value;
                } else {
                  acc[k] = value;
                }
              }
              return acc;
            }, {}) as ElementOf<Guardian["seasonAggregates"]>;
            const totalKills = sanitizeNumber(all.kills);
            return [
              {
                display: (
                  <div className="prow">
                    <SquareAvatar
                      avatar={bungieAsset(
                        filterErrState(characters?.[g.characterId])?.character
                          ?.emblemPath,
                      )}
                    />
                    <div className="avatar-label">
                      <span>{g.guardianClass}</span>
                      <span>{g.name}</span>
                    </div>
                  </div>
                ),
                value: 100,
                align: "left",
              },
              {
                display: totalKills.toLocaleString(language),
                value: totalKills,
                align: "center",
              },
              {
                display: displayRate(all.precisionKills, totalKills),
                value: sanitizeNumber(all.precisionKills / totalKills),
                align: "right",
              },
            ];
          })
          .filter(Boolean)}
      />
    </ProfileCard>
  );
};

const Weapons = ({ bungieId }: { bungieId: string }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const weaponStatsByProfile = useProfileWeaponStats(bungieId);
  const cols = [
    { display: t("common:weapons", "Weapons"), primary: true, align: "left" },
    { display: t("common:kills", "Kills"), isStat: true, align: "right" },
    {
      display: t("common:stats.hsPercent", "HS%"),
      isStat: true,
      align: "right",
    },
  ];
  const rows = weaponStatsByProfile.map((data) => {
    const totalKills = data.kills ?? 0;
    return [
      {
        display: data.weapon.name,
        value: data.weapon.name,
      },
      { display: totalKills.toLocaleString(language), value: totalKills },
      {
        display: displayRate(data.precisionKills, totalKills, 0, 1),
        value: sanitizeNumber(data.precisionKills / totalKills),
      },
    ];
  });

  return (
    <ProfileCard>
      <p className="card-title">{t("common:navigation.weapons", "Weapons")}</p>
      <DataTable cols={cols} rows={rows} sortCol={1} />
    </ProfileCard>
  );
};

const Maps = ({ bungieId }: { bungieId: string }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const state = useSnapshot(readState);
  const { seasons, profiles, mapStats } = state.destiny2;
  const [guardian] = useQuery("guardian");
  const [seasonId] = useQuery("season", seasons[0]?.id);
  const $profile = profiles[bungieId];
  const profile = useEvalState($profile);
  const guardians = useMemo(
    () => profile?.memberships.flatMap?.((i) => i.guardians) ?? [],
    [profile?.memberships],
  );
  const mapData = useMemo(() => {
    const mapsByCharacter: Array<GuardianMapStats[0]> = guardians.reduce(
      (acc, cur) => {
        const value = filterErrState(
          mapStats[btoa(seasonId + cur.characterId)],
        );
        if (!value) return acc;
        acc.push(value);
        return acc;
      },
      [],
    );
    const hash = {};
    for (const maps of mapsByCharacter) {
      for (const map in maps) {
        const data = clone(maps[map]);
        if (isEmpty(data)) continue;
        if (guardian)
          for (const k in data)
            if (data[k].guardian.guardianClass !== guardian) delete data[k];
        const result = getGuardianClassAggregates(data);
        if (hash[map]) {
          for (const k in hash[map]) {
            hash[map][k] = (hash[map][k] ?? 0) + result[k];
          }
        } else {
          hash[map] = result;
        }
      }
    }
    return Object.entries(hash)
      .filter((i) => (i[1] as MapStats).secondsPlayed)
      .sort((a, b) => (b[1] as MapStats).wins - (a[1] as MapStats).wins)
      .slice(0, 10) as Array<[string, MapStats]>;
  }, [mapStats, guardian, guardians, seasonId]);

  const cols = [
    { display: t("common:location", "Location"), primary: true, align: "left" },
    { display: t("common:kills", "Kills"), isStat: true, align: "right" },
    {
      display: t("common:stats.hsPercent", "HS%"),
      isStat: true,
      align: "right",
    },
  ];

  const rows = mapData.map(([mapName, data]) => {
    const totalKills = data.kills ?? 0;
    return [
      {
        display: mapName,
        value: mapName,
      },
      { display: totalKills.toLocaleString(language), value: totalKills },
      {
        display: displayRate(data.precisionKills, totalKills, 0, 1),
        value: sanitizeNumber(data.precisionKills / totalKills),
      },
    ];
  });

  return (
    <ProfileCard>
      <p className="card-title">{t("common:maps", "Maps")}</p>
      <DataTable
        cols={cols}
        rows={rows}
        sortCol={1}
        noResultsTitle={["destiny2:suggestions.noMapStats", "No map stats"]}
        noResultsDescription={[
          "destiny2:suggestions.noMapStatsDescription",
          "We currently do no have any map stats for you.",
        ]}
      />
    </ProfileCard>
  );
};

const SidebarWidgets = ({ bungieId }) => {
  const {
    destiny2: { profiles },
  } = useSnapshot(readState);
  const profile = useEvalState(profiles[bungieId]);
  return (
    <>
      <Headshots bungieId={bungieId} />
      <Guardians bungieId={bungieId} />
      {!!profile?.blitzUser && <Weapons bungieId={bungieId} />}
      <Maps bungieId={bungieId} />
    </>
  );
};

export default function Sidebar({ bungieId }: { bungieId: string }) {
  return <SidebarWidgets bungieId={bungieId} />;
}
